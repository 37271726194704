<template>
  <div
    class="mb-25 w-100"
    v-if="result && result.additional_fees && result.additional_fees.length"
  >
    <div
      v-for="(item, index) in result.additional_fees"
      :key="index"
      class="d-flex justify-content-between"
    >
      <h6 class="size10 text-black fw-bold-500 mb-0 text-capitalize">
        {{ item.additional_fee_note }}
      </h6>
      <h6 class="size10 text-black fw-bold-500 mb-0">
        {{ item.additional_fee_amount | formatAmount }}
      </h6>
    </div>
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

.
<style scoped>
.text-black {
  color: #000;
}
</style>
