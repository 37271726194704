<template>
    <div class="thermal-print">
      <!-- Logo -->
      <img v-if="result.branch && (result.branch.photo_url.includes('png') || result.branch.photo_url.includes('jpg'))"
           :src="result.branch.photo_url"
           alt="logo"
           style="margin-left: 145px; width: 100px; height: auto">
  
      <!-- Header -->
      <div class="header">
        <div v-if="result.branch" class="branch-name">{{ result.branch.name || "-" }}</div>
        <div v-if="result.branch" class="branch-address">{{ result.branch.address || "-" }}</div>
        <div v-if="result.branch" class="branch-phone">Tlp: {{ result.branch.phone }}</div>
      </div>
  
      <!-- Invoice Details -->
      <div class="invoice-details">
        <div>No. Invoice: {{ result.invoice_number || "-" }}</div>
        <div v-if="result.po_so_number">No. PO SO: {{ result.po_so_number || "-" }}</div>
        <div v-if="deliveryNumbers && deliveryNumbers.length">No. Surat Jalan: {{ deliveryNumbers.join(", ") || "-" }}</div>
        <div>Tanggal: {{ result.date || "-" }}</div>
        <div>Sales: {{ (result.seller && result.seller.name) || "-" }}</div>
        <div>Kasir: {{ (result.operator && result.operator.name) || "-" }}</div>
        <div v-if="result.customer">Pelanggan: {{ result.customer.name }}</div>
        <div v-if="result.delivery_pickup === 'delivery'">Pengiriman: {{ result.delivery_pickup || "-" }}</div>
      </div>
  
      <div class="divider"></div>
  
      <!-- Product List -->
      <div class="product-list">
        <div v-for="(item, index) in result.items" :key="index" class="product-item">
          <div class="product-name">{{ item.name }}</div>
          <div class="product-details">
            <span>{{ item.qty.toString().replace(".", ",") }} {{ item.unit }}</span>
            <span>{{ item.sub_price | formatInvoice }}</span>
            <span v-if="item.discount_per_item > 1">-{{ item.discount_per_item | formatInvoice }}</span>
            <span>{{ item.total | formatInvoice }}</span>
          </div>
        </div>
      </div>
  
      <div class="divider"></div>
  
      <!-- Summary -->
      <div class="summary">
        <div class="summary-item">
          <span>Subtotal</span>
          <span>{{ formatAmountAndroid(result.subtotal) }}</span>
        </div>
        
        <div v-if="result.additional_fee_total > 0" class="summary-item">
          <span>Total Biaya Tambahan</span>
          <span>{{ formatAmountAndroid(result.additional_fee_total) }}</span>
        </div>
  
        <!-- Split Payment -->
        <template v-if="result.payments && result.payments.length > 1">
          <div class="divider"></div>
          <div class="summary-header">Pembayaran Terbagi</div>
          <div v-for="(payment, indexPayment) in result.payments" :key="indexPayment" class="summary-item">
            <span>
              {{ paymentMethods(payment.payment_method) }}
              {{ payment.payment_method == "transfer" ? " ke " + payment.bank_account_payment.bank.name : "" }}
            </span>
            <span>{{ formatAmountAndroid(parseFloat(payment.amount)) }}</span>
          </div>
          <div class="divider"></div>
          <div class="summary-item">
            <span>Total Pembayaran</span>
            <span>{{ formatAmountAndroid(sumSplitPayment(result.payments)) }}</span>
          </div>
        </template>
  
        <!-- Discount -->
        <template v-if="result.discount_value > 0">
          <div class="summary-item">
            <span>Diskon Tambahan</span>
            <span v-if="result.discount_type == 'fixed'">
              - {{ formatAmountAndroid(result.discount_value) }}
            </span>
            <span v-if="result.discount_type == 'percent'">
              - {{ result.discount_value }}%
            </span>
          </div>
        </template>
  
        <div v-if="result.discount_total > 0" class="summary-item">
          <span>Total Diskon</span>
          <span>{{ formatAmountAndroid(result.discount_total) }}</span>
        </div>
  
        <div class="summary-item total">
          <span>Grand Total</span>
          <span>{{ formatAmountAndroid(result.total) }}</span>
        </div>
  
        <template v-if="result.payment_method !== 'piutang' && result.piutang && result.piutang.amount > 0">
          <div class="summary-item">
            <span>Piutang</span>
            <span>{{ formatAmountAndroid(result.piutang.amount) }}</span>
          </div>
        </template>
  
        <template v-if="result.payment_method !== 'piutang'">
          <div class="summary-item" v-if="result.payment_method === 'wallet'">
            <span>Sisa Deposit</span>
            <span>{{ formatAmountAndroid(result.payment_customer && result.payment_customer.wallet_balance) }}</span>
          </div>
          <div class="summary-item" v-if="result.cash_change > 0 || (result.returs_id && result.returs_id.length)">
            <span>Kembali</span>
            <span>{{ formatAmountAndroid(result.cash_change) }}</span>
          </div>
        </template>
  
        <div v-if="result.discount_total > 0" class="summary-item">
          <span>Anda Hemat</span>
          <span>{{ formatAmountAndroid(result.discount_total) }}</span>
        </div>
      </div>
  
      <!-- Notes -->
      <div class="notes">
        <div class="note-header">Note:</div>
        <div v-if="result.display_vat === 1" class="note-item">*Harga barang sudah termasuk PPn 11%</div>
        <div class="note-item">{{ result.invoice_notes }}</div>
      </div>
  
      <!-- Additional Info -->
      <div v-if="result.returs_id && result.returs_id.length > 0" class="additional-info">
        {{ result.returs_id.join(", ") }}
      </div>
  
      <div v-if="result.deleted_by" class="cancellation-info">
        Dibatalkan oleh : {{ result.deleted_by.name }} / {{ result.deleted_at }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThermalPrintTemplate',
    props: {
      result: Object,
      merchant: Object,
      deliveryNumbers: Array
    },
    methods: {
      formatInvoice(value) {
        // Implement your formatting logic here
        return value;
      },
      formatAmountAndroid(value) {
        // Implement your formatting logic here
        return value;
      },
      paymentMethods(method) {
        const paymentMapping = {
          cash: "Tunai",
          card: "Debit/Kredit",
          transfer: "Transfer",
          giro: "Giro",
          wallet: "Deposit",
          piutang: "Piutang",
        };
        return paymentMapping[method] || "-";
      },
      sumSplitPayment(payments) {
        return payments.reduce((total, payment) => total + parseFloat(payment.amount), 0);
      }
    }
  }
  </script>
  
  <!-- <style scoped>
  .thermal-print {
    font-family: 'Courier', monospace;
    font-size: 12px;
    line-height: 1.2;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 0;
  }
  
  .logo {
    display: block;
    margin: 0 auto 10px;
    max-width: 60px !important;
    height: auto;
  }
  
  .header, .invoice-details, .product-list, .summary, .notes {
    margin-bottom: 10px;
  }
  
  .branch-name {
    font-size: 42px !important;
    font-weight: bold;
  }
  
  .divider {
    border-top: 1px dashed #000;
    margin: 10px 0;
  }
  
  .product-item {
    margin-bottom: 5px;
  }
  
  .product-name {
    font-weight: bold;
  }
  
  .product-details {
    display: flex;
    justify-content: space-between;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  
  .summary-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .total {
    font-weight: bold;
    font-size: 42px !important;
    margin-top: 5px;
  }
  
  .note-header {
    font-weight: bold;
    margin-bottom: 2px;
  }
  
  .note-item {
    font-style: italic;
  }
  
  .additional-info, .cancellation-info {
    margin-top: 10px;
    font-style: italic;
  }
  
  .cancellation-info {
    color: #ff0000;
  }
  </style> -->